<template>
  <div class="container">
    <div style="position:relative;height:60px;background-color: #136AFB">
      <div class="toptitle">个人中心</div>
      <img :src="require('../../public/back.png')" class="back" @click="$router.back()"/>
    </div>
    <div class="topline">
      <img :src="require('../../public/user.png')" style="width:20px"/>
      <span class="username">{{ user.student_lastName + user.student_givenName }}</span>
    </div>
    <div>
      <div class="form" @click="toInfoSet">
        <el-form :model="user">
          <el-form-item label="手机号">
            <el-input v-model="user.student_mobile" disabled></el-input>
          </el-form-item>
          <el-form-item label="学校">
            <el-input v-model="account_info.account_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="user.student_email" disabled></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-input v-model="user.student_gender" disabled></el-input>
          </el-form-item>
          <el-form-item label="年级">
            <el-input v-model="user.student_graduation" disabled></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-bottom: 10px">
        <div class="applicationbtn" @click="toEvents">科目列表</div>
        <div class="applicationbtn" @click="toApp">我的报名表</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUnionId, setUnionId, setUserName, setUserId, setAccountId} from '../utils/store'
import { getStudentInfoByUnionid, getAccount } from '../api/test'

export default {
  data () {
    return {
      account_info: {},
      user: {}
    }
  },
  created () {
    // setUnionId('oD3ql0ahOWwhMTrvOlLoEnimE0v0')
    this.getUser()
  },
  methods: {
    toApp () {
      this.$router.push('/applications')
    },
    toInfoSet () {
      this.$router.push({
        path: '/setting'
      })
    },
    getAccountInfo (account_id) {
      getAccount(account_id).then((res) => {
        if (res.data.code === 0) {
          this.account_info = res.data.data
        }
      })
    },
    getUser () {
      var unionid = getUnionId()
      getStudentInfoByUnionid(unionid).then(res => {
        if (res.data.code === 0) {
          this.user = res.data.data
          var user_id = this.user._id.$id
          setUserId(user_id)
          setAccountId(this.user.account_id)
          var usr_name = this.user.student_lastName + this.user.student_givenName
          setUserName(usr_name)
          this.getAccountInfo(this.user.account_id)
        }
      })
    },
    toAuth () {
      this.$router.push({
        path: '/auth'
      })
    },
    toEvents () {
      this.$router.push('/events')
    }
  }
}
</script>

<style>
.bottombtn {
  width: 100%;
  position: absolute;
  bottom: 30px;
}

.applicationbtn {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #4285F4;
  border: 1px solid #4285F4;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 14px;
}

.logoutbtn {
  width: 80%;
  margin: 0 auto;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #8F0001;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  color: #8F0001;
  text-align: center;
  margin-top: 14px;
}

.userinfoblock {
  width: 72%;
  margin: 10px auto;
  padding: 20px;
  border-bottom: 1px solid #E4E7ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userinfotxt {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 40px;
  color: #212121;
}

.usernametxt {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 24px;
  color: #AEAEAE;
}

.form {
  width: 80%;
  margin: 0 auto;
}
</style>
