<template>
  <div class="container">
    <div style="position:relative;height:60px;background-color: #136AFB">
      <div class="toptitle">科目报名</div>
      <img :src="require('../../public/back.png')" class="back" @click="$router.back()"/>
    </div>
    <div class="topline" @click="toHome">
      <img :src="require('../../public/user.png')" style="width:20px"/>
      <span class="username">{{ user_name }}</span>
    </div>
    <div class="form">
      <div class="txt">项目</div>
      <el-select v-model="select_project_code" @change="handleProjectChanged" style="margin-top:10px;width:100%">
        <el-option v-for="item in projects" :key="item._id.$id" :label="item.project_name"
                   :value="item.project_code"></el-option>
      </el-select>
      <div class="txt" style="margin-top: 15px">科目</div>
      <el-select v-model="select_event_name" style="margin-top:10px;width:100%">
        <el-option v-for="item in events" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <div @click="submit" class="loginbtn">确认报名</div>
    </div>
  </div>
</template>

<script>
import {getAccountId, getUnionId, getUserId, getUserName} from '../utils/store'
import {addNewApplication, getEvent, getProjects, getStudentInfoByUnionid} from '../api/test'

export default {
  data() {
    return {
      user_id: '',
      event_id: this.$route.query.event_id,
      projects: [],
      select_project_code: '',
      select_event_name: '',
      events: [],
      userInfo: {}
    }
  },
  created() {
    this.user_id = getUserId()
    this.user_name = getUserName()
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.event_id) {
        getEvent(this.event_id).then((res) => {
          var temp = res.data.data
          this.select_event_name = temp.name
        })
      }
      getProjects().then((res) => {
        this.projects = res.data.data
      })
      var unionid = getUnionId()
      getStudentInfoByUnionid(unionid).then(res => {
        if (res.data.code === 0) {
          this.userInfo = res.data.data
        }
      })
    },
    handleProjectChanged() {
      for (var i = 0; i < this.projects.length; i++) {
        var project = this.projects[i]
        if (project.project_code === this.select_project_code) {
          this.events = project.project_events
        }
      }
    },
    submit() {
      if (!this.select_project_code) {
        this.$message.error('请完成必填选项')
        return
      }
      var params = {
        project_code: this.select_project_code,
        student_email: this.userInfo.student_email,
        account_id: getAccountId(),
        student_lastName: this.userInfo.student_lastName,
        student_givenName: this.userInfo.student_givenName,
        student_lastName_pinyin: this.userInfo.student_lastName_pinyin,
        student_givenName_pinyin: this.userInfo.student_givenName_pinyin,
        student_gender: this.userInfo.student_gender,
        student_graduation: this.userInfo.student_graduation,
        student_mobile: this.userInfo.student_mobile,
        student_guardian_first_mobile: this.userInfo.student_guardian_first_mobile,
        student_guardian_first_email: this.userInfo.student_guardian_first_email,
        unionid: getUnionId(),
        application_event: this.select_event_name
      }
      addNewApplication(params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '报名成功',
            type: 'success'
          })
          this.$router.push({
            path: '/application',
            query: {
              application_id: res.data.data.application_id
            }
          })
        } else {
          this.$message.error('您已报名过本季度的其他科目，不能再报名该科目')
        }
      })
    },
    toHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.container {
  overflow: hidden
}

.form {

}

.datetxt {
  font-size: 12px;
  color: #909399;
  line-height: 10px;
}
</style>
