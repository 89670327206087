<template>
    <div class="container">
        <img :src="require('../../public/top.png')"  style="width:60%;"/>
        <div style="text-align:center;">
          <img :src="require('../../public/title.png')" style="width:56%"/>
        </div>
        <div class="form">
          <div class="txt">手机号</div>
          <el-input v-model="form.mobile" style="margin-top:6px"></el-input>
          <div class="txt" style="margin-top:10px">验证码</div>
          <div style="display:flex;margin-top:6px;justify-content:space-between">
            <el-input v-model="form.pin" style="width:64%"></el-input>
            <div class="pinbtn" @click="senLoginPin">{{countDownTimes}}</div>
          </div>

          <div @click="verifyLoginPin" class="loginbtn">登录</div>
          <!-- <div style="border-top:1px solid #707070;margin-top:40px">
            <p style="text-align:center;color:#C8C8C8;font-size:12px">使用第三方账户登陆</p>
            <div style="text-align:center">
              <img :src="require('../../public/wechat.png')" style="width:36px" @click="loginByWechat">
            </div>
          </div> -->
        </div>
    </div>
</template>

<script>
import { setUserId, setUserName, getOpenId } from '../utils/store'
import { createLoginPin, verifyLoginPin, loginByOpenId } from '../api/test'
export default {
  data () {
    return {
      form: {
        mobile: '',
        pin: ''
      },
      countDownTimes: '发送验证码',
      enableSendMsg: true
    }
  },
  created () {

  },
  methods: {
    senLoginPin () {
      if (!this.enableSendMsg) {
        return
      }
      if (!this.form.mobile) {
        return
      }
      this.enableSendMsg = false
      createLoginPin(this.form.mobile).then(res => {
        if (res.data) {
          this.startCountDown()
          this.$message({
            message: '发送成功',
            type: 'success'
          })
        } else {
          this.$message.error('获取验证码失败，请稍后重试！')
          this.enableSendMsg = true
        }
      })
    },
    verifyLoginPin () {
      verifyLoginPin(this.form.mobile, this.form.pin, getOpenId()).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '登陆成功',
            type: 'success'
          })
          setUserId(res.data.data._id.$id)
          setUserName(res.data.data.real_name)
        //   this.$router.push('/home')
        window.open('https://web-backend.reachable-edu.com/FBLC_resource_center/FBLCAuth.php?route=home')
        } else if (res.data.code == 1) {
          this.$message.error('验证码错误')
        }
      })
    },
    loginByWechat () {
      loginByOpenId(getOpenId()).then(res => {
        if (res.data.code === 0) {
          setUserId(res.data.data._id.$id)
          setUserName(res.data.data.user_name)
          this.$router.push('/home')
        }
      })
    },
    startCountDown () {
      let totalTime = 60
      const countDownThread = window.setInterval(() => {
        const remainTime = totalTime
        this.countDownTimes = remainTime + '秒后重发'
        totalTime--
        if (totalTime <= 0) {
          this.enableSendMsg = true
          this.countDownTimes = '发送验证码'
          window.clearInterval(countDownThread)
        }
      }, 1000)
    }
  }
}
</script>

<style>
.container{
  height: 100vh;
}
.form{
    width:80%;
    margin:0 auto;
}
.txt{
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
  opacity: 1;
  margin-top: 10px;
}
.loginbtn{
  cursor: pointer;
  width: 100%;
  height: 40px;
  background: #4285F4;
  border: 1px solid #4285F4;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  color: #FFFFFF;
  text-align: center;
  margin-top:40px
}
.pinbtn{
  width: 32%;
  height: 38px;
  background: #8F0001;
  border: 1px solid #8F0001;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
}
</style>
